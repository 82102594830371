/* Footer */
.footer-section {
  background: #3d3e42;
  padding: 0.5rem 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*************************************** Media Queries ***************************************/

/************************* For Small Screens (Mobiles) *************************/
@media screen and (max-width: 767px) {
  .footer-section {
    padding: 0.5rem 2rem;
  }
}
