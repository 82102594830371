.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  height: 5rem;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1030;
  padding: 0 1rem;
  padding-inline: var(--container-margin);
}

.navbar.active {
  background: var(--bg-dark);
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
}

.navbar__logo img {
  height: 3rem;
  margin: 5px 0;
}

.navbar__list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 2rem;
}

.navbar__list-item a {
  color: var(--text-light);
  text-decoration: none;
  outline: none;
  opacity: 0.8;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
}

.navbar__list-item a:hover,
.navbar__list-item a.active {
  color: var(--text-light);
  opacity: 1;
}

.navbar__list .navbar__list-item-close {
  position: absolute;
  top: 1.5rem;
  right: 2rem;
  color: var(--text-light);
  font-size: 1.5rem;
  cursor: pointer;
  display: none;
}

.navbar__search {
  display: flex;
  align-items: center;
  position: relative;
  gap: 1rem;
}

.navbar__search i {
  color: var(--text-light-tint);
  font-size: 1.5rem;
  cursor: pointer;
}

.navbar__search .navbar__search-input {
  position: absolute;
  top: 0;
  right: 3rem;
  transform: translateY(-200%);
  transition: all 0.3s ease-in-out;
}

.navbar__search .navbar__search-input.active {
  transform: translateY(0);
}

.navbar__search .navbar__search-input input {
  background: var(--bg-dark-tint);
  color: var(--text-light);
  border: none;
  outline: none;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  width: calc(100% + 12);
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.navbar__search .navbar__search-input .navbar__search-input-result {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: var(--bg-dark-tint);
  border-radius: 0.5rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
  max-height: 20rem;
  overflow-y: auto;
}

.navbar__search .navbar__search-input .navbar__search-input-result.active {
  opacity: 1;
  pointer-events: all;
}

.navbar__search
  .navbar__search-input
  .navbar__search-input-result
  .navbar__search-input-result-item {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-bottom: 1px solid var(--bg-dark);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.navbar__search
  .navbar__search-input
  .navbar__search-input-result
  .navbar__search-input-result-item:hover {
  background: var(--bg-dark);
}

.navbar__search
  .navbar__search-input
  .navbar__search-input-result
  .navbar__search-input-result-item
  .title {
  color: var(--text-light);
  font-weight: 500;
}

.navbar__search
  .navbar__search-input
  .navbar__search-input-result
  .navbar__search-input-result-item
  .category {
  color: var(--text-light-tint);
  font-size: 0.8rem;
}

.navbar__search
  .navbar__search-input
  .navbar__search-input-result
  .navbar__search-input-result-item
  .description {
  color: var(--text-light-tint);
  font-size: 0.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-block: 0.5rem;
}

.navbar__btn {
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  color: var(--text-light);
  font-size: 2rem;
  display: none;
  transition: all 0.3s ease-in-out;
}

.navbar__btn:hover {
  color: var(--text-light-tint);
}

/*************************************** Media Queries ***************************************/

/************************* For Small Screens (Mobiles) *************************/
@media screen and (max-width: 767px) {
  .navbar {
    padding-inline: 2rem;
  }

  .navbar__btn {
    display: block;
  }

  .navbar__list {
    position: fixed;
    inset: 0;
    background: var(--bg-dark);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 5rem 0;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease-in-out;
  }

  .navbar__list.active {
    opacity: 1;
    pointer-events: all;
  }

  .navbar__list-item a {
    font-size: 1.5rem !important;
  }

  .navbar__list-item .navbar__list-item-close {
    display: block;
  }

  .navbar__search {
    display: none;
  }
}
