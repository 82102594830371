.spinner {
  position: fixed;
  inset: 0;
  background-color: var(--bg-dark-tint);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease-in-out;
  visibility: visible;
  opacity: 1;
}

.spinner.hidden {
  opacity: 0;
  pointer-events: none;
}

.spinner img {
  animation: wobbly-scale 1s ease-in-out infinite;
}

@keyframes wobbly-scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
