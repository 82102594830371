.hero-section {
  height: 100svh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 0 10rem;
  position: relative;
  z-index: 1;
  box-shadow: 0 0 10rem rgba(0, 0, 0, 0.2);
}

.hero-section .hero-text {
  flex: 1;
}

.hero-section h3 span {
  font-family: "Gugi", cursive;
  font-size: 2.5rem;
  color: var(--primary-color);
}

.hero-section p {
  margin-bottom: 2rem;
}

.hero-section .hero-image {
  position: relative;
  height: 100%;
  flex: 0.8;
}

.hero-section .hero-buttons {
  display: flex;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.hero-section .hero-image img {
  width: 40rem;
  height: auto;
  object-fit: cover;
  position: absolute;
  bottom: 0;
  right: 0;
  filter: grayscale(100%) contrast(1) brightness(90%)
    drop-shadow(0 -0.5rem 0.5rem hwb(32 12% 3% / 0.5));
}

.hero-section .hero-image .social-links {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 1rem;
}

.hero-section .hero-image .social-links li {
  list-style: none;
  margin-bottom: 2rem;
}

.hero-section .hero-image .social-links li a {
  color: var(--text-light);
  text-decoration: none;
  font-size: 2rem;
  transition: all 0.3s ease-in-out;
}

.hero-section .hero-image .social-links li a:hover {
  color: var(--primary-color);
}

.hero-section .hero-image .social-links::after {
  content: "";
  position: absolute;
  bottom: 20rem;
  left: 50%;
  right: 0;
  transform: translateX(-50%);
  width: 0.2rem;
  height: 10rem;
  background-color: var(--primary-color);
  border-radius: 0.5rem;
}

.hero-section .btn.btn-primary {
  border: 3px solid var(--primary-color);
}

.hero-section .btn.btn-primary:hover {
  border: 3px solid transparent;
}

/*  About Section  */

.about-section {
  background-color: #31333b;
  padding: 8rem 10rem;
}

.about-section > p {
  margin-bottom: 10rem;
  margin-left: 7rem;
  text-align: justify;
  position: relative;
}

.about-section p::before {
  content: "";
  position: absolute;
  top: 1rem;
  left: -7rem;
  width: 5rem;
  height: 0.2rem;
  background-color: var(--primary-color);
  border-radius: 0.5rem;
}

.about-section p span {
  color: var(--primary-color);
}

.about-section .skills h3 {
  text-align: center;
}

.about-section .skills-container,
.about-section .portfolio-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 5rem;
}

.about-section .skills-container .skill {
  width: calc(20% - 2rem);
  min-width: 15rem;
  height: 15rem;
  background: #3d3e42;
  border-radius: 1rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.about-section .skills-container .skill:hover {
  transform: translateY(-1rem);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  background: #f0bf6c;
}

.about-section .skills-container .skill:hover img {
  filter: grayscale(0) contrast(1) brightness(100%)
    drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.5));
}

.about-section .skills-container .skill img {
  width: 5rem;
  height: auto;
  margin-bottom: 1rem;
  transition: all 0.3s ease-in-out;
}

.about-section .skills-container .skill span {
  text-align: center;
}

/*  Portfolio Section  */

.portfolio-section {
  padding: 8rem 10rem;
}

.portfolio-section h3 {
  text-align: center;
}

.about-section .portfolio-container {
  margin-block: 3rem;
  justify-content: space-evenly;
}

.portfolio-section .portfolio-container .portfolio {
  width: calc(33.333% - 2rem);
  min-width: 20rem;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.portfolio-section .portfolio-container .portfolio img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.portfolio-section .portfolio-container .portfolio .portfolio-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transform: translateY(100%);
  transition: all 0.3s ease-in-out;
  text-align: center;
}

.portfolio-section .portfolio-container .portfolio:hover .portfolio-text {
  opacity: 1;
  transform: translateY(0);
}

.portfolio-section .portfolio-container .portfolio .portfolio-text small {
  color: var(--text-light);
  text-align: center;
  margin-bottom: 2rem;
}

/*  Experience Section  */
.experience-section {
  background: #3d3e42;
  padding: 5rem 10rem;
}

.experience-section > h3 {
  position: relative;
  top: -7.3rem;
}

.experience-section .experience-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 5rem;
}

.experience-section .experience-container .experience {
  margin-inline: 10rem;
  display: flex;
  margin-bottom: 3rem;
}

.experience-section .experience-container .experience > p {
  margin-bottom: 2rem;
  margin-right: 5rem;
  background: #5e5f62;
  padding: 0.5rem 1rem;
  height: fit-content;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
  position: relative;
}

/* Triangle */
.experience-section .experience-container .experience > p::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -1.4rem;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 1.45rem solid transparent;
  border-bottom: 1.45rem solid transparent;
  border-left: 1.45rem solid #5e5f62;
}

.experience-section .experience-container .experience .experience-text {
  position: relative;
}

.experience-section .experience-container .experience .experience-text::before {
  content: "";
  position: absolute;
  background: var(--primary-color);
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  left: -3rem;
  top: 1rem;
  z-index: 1;
  box-shadow: 0 0 1rem #f7931e;
}

.experience-section .experience-container .experience .experience-text::after {
  content: "";
  position: absolute;
  background: #fff;
  width: 0.15rem;
  height: 100%;
  left: -2.35rem;
  top: 4rem;
  border-radius: 0.5rem;
  z-index: 1;
}

.experience-section
  .experience-container
  .experience
  .experience-text
  p:last-child {
  font-family: "Exec";
  font-weight: 400;
  opacity: 0.7;
}

/* Contact Section */
.contact-section {
  padding: 5rem 10rem;
  background: #31333b;
}

.contact-section h3 {
  text-align: center;
  position: relative;
  top: -7.3rem;
}

.contact-section h3::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 0.2rem;
  background-color: var(--primary-color);
  border-radius: 0.5rem;
}

.contact-section h3::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
  width: 80%;
  height: 0.2rem;
  background-color: var(--primary-color);
  border-radius: 0.5rem;
}

.contact-section .contact-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
  width: 50%;
  margin-inline: auto;
}

.contact-section .contact-form .form-group .text-danger {
  color: #e73838;
}

.contact-section .contact-form .form-group .form-control {
  width: 100%;
  height: 3rem;
  background: #3d3e42;
  border: 2px solid #3d3e42;
  outline: none;
  border-radius: 0.5rem;
  padding: 0 1rem;
  color: var(--text-light);
  font-family: "Folty";
  font-weight: 400;
  font-size: 1.2rem;
  margin-block: 1rem;
  transition: all 0.3s ease-in-out;
}

.contact-section .contact-form .form-group .form-control:focus {
  border: 2px solid var(--primary-color);
}

.contact-section .contact-form .form-group textarea.form-control {
  height: 15rem;
  padding: 1rem;
}

.contact-section .social-links {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
}

.contact-section .social-links a {
  color: var(--text-light);
  text-decoration: none;
  font-size: 2rem;
  transition: all 0.3s ease-in-out;
}

.contact-section .social-links a:hover {
  color: var(--primary-color);
}

/*************************************** Media Queries ***************************************/

/************************* For Large Screens (Laptops and Desktops) *************************/
@media screen and (min-width: 1025px) and (max-width: 1280px) {
  .hero-section {
    padding: 0 5rem;
  }

  .hero-section .hero-text {
    flex: 1;
  }

  .hero-section .hero-image {
    flex: 1;
  }

  .hero-section .hero-image img {
    width: 30rem;
    height: calc(100% - 10rem);
  }

  .about-section {
    padding: 5rem 5rem;
  }

  .about-section .skills-container,
  .about-section .portfolio-container {
    justify-content: space-evenly;
  }

  .about-section .skills-container .skill {
    width: calc(25% - 2rem);
  }

  .about-section .portfolio-container .portfolio {
    width: calc(50% - 2rem);
  }

  .portfolio-section {
    padding: 8rem 5rem !important;
  }

  .experience-section {
    padding: 5rem 5rem;
  }

  .experience-section .experience-container .experience {
    margin-inline: 5rem;
  }

  .contact-section {
    padding: 5rem 5rem;
  }
}

/************************* For Medium Screens (Tablets) *************************/
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .hero-section {
    padding: 0 2rem;
  }

  .hero-section .hero-text {
    flex: 1;
  }

  .hero-section .hero-image {
    flex: 1;
  }

  .hero-section .hero-image img {
    width: 30rem;
    height: calc(100% - 10rem);
  }

  .social-links {
    display: none;
  }

  .about-section {
    padding: 5rem 2rem;
  }

  .about-section .skills-container,
  .about-section .portfolio-container {
    justify-content: space-evenly;
  }

  .about-section .skills-container .skill {
    width: calc(25% - 2rem);
  }

  .about-section .portfolio-container .portfolio {
    width: calc(50% - 2rem);
  }

  .portfolio-section {
    padding: 8rem 0rem;
  }

  .experience-section {
    padding: 5rem 2rem;
  }

  .experience-section .experience-container .experience {
    margin-inline: 5rem;
  }

  .contact-section {
    padding: 5rem 2rem;
  }

  .contact-section h3::before,
  .contact-section h3::after {
    width: 60%;
  }

  .contact-section .contact-form {
    width: 100%;
  }
}

/************************* For Small Screens (Mobiles) *************************/
@media screen and (max-width: 767px) {
  .hero-section {
    padding: 0 2rem;
    flex-direction: column;
    gap: 2rem;
    padding-top: 10rem;
  }

  .hero-section .hero-text {
    flex: 1;
  }

  .hero-section .hero-image {
    flex: 1;
    position: static;
  }

  .hero-section .hero-image img {
    display: none;
  }

  .hero-section .hero-image .social-links {
    display: none;
  }

  .hero-section .hero-buttons {
    flex-direction: column;
    gap: 2rem;
  }

  .hero-section .hero-buttons .btn {
    width: 100%;
    text-align: center;
  }

  .about-section {
    padding: 5rem 2rem;
  }

  .about-section p {
    margin-left: 0;
  }

  .about-section .skills-container,
  .about-section .portfolio-container {
    justify-content: space-evenly;
  }

  .about-section .skills-container .skill {
    width: calc(50% - 2rem);
  }

  .about-section .portfolio-container .portfolio {
    width: calc(100% - 2rem);
  }

  .portfolio-section {
    padding: 8rem 0rem;
  }

  .experience-section {
    padding: 5rem 2rem;
  }

  .experience-section .experience-container .experience {
    margin-inline: 0rem;
  }

  .experience-section .experience-container .experience > p {
    margin-right: 5rem;
  }

  .experience-section .experience-container .experience > p:first-child {
    font-size: 1.2rem !important;
  }

  .experience-section .experience-container .experience > p:first-child::after {
    border-top: 1.2rem solid transparent;
    border-bottom: 1.2rem solid transparent;
    border-left: 1.2rem solid #5e5f62;
    right: -1.2rem;
  }

  .contact-section {
    padding: 5rem 2rem;
  }

  .contact-section h3 {
    top: -7rem;
  }

  .contact-section h3::before,
  .contact-section h3::after {
    width: 30%;
  }

  .contact-section .contact-form {
    width: 100%;
  }
}
