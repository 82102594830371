@import url("https://db.onlinewebfonts.com/c/720366d14c82e5c03d4af2b2f6d55603?family=Morandi+W01+Regular");
@import url("https://fonts.googleapis.com/css2?family=Gugi&display=swap");
@import url("https://db.onlinewebfonts.com/c/6bff8badb403b5b6196f53b3234a829a?family=Glosa+W01+Text+Black");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.2/font/bootstrap-icons.min.css");
@font-face {
  font-family: "Folty";
  src: local("Folty Medium"), local("Folty-Medium"),
    url("assets/fonts/Folty/Folty-Medium.woff2") format("woff2"),
    url("assets/fonts/Folty/Folty-Medium.woff") format("woff"),
    url("assets/fonts/Folty/Folty-Medium.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Exec";
  src: local("Exec Regular"), local("Exec-Regular"),
    url("assets/fonts/Exec/Exec-Regular.woff2") format("woff2"),
    url("assets/fonts/Exec/Exec-Regular.woff") format("woff"),
    url("assets/fonts/Exec/Exec-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

:root {
  --bg-dark: #34353a;
  --bg-dark-tint: #3d3e42;
  --bg-light: #f5f5f5;
  --bg-lighter: #fff;
  --text-dark: #34353a;
  --text-light-tint: #ffffff80;
  --text-light: #f5f5f5;
  --text-lighter: #fff;
  --primary-color: #f7931e;
  --primary-color-tint: #f7931e80;
  --primary-color-shade: #f7931e60;
  --primary-color-dark: #f7931e40;
  --primary-color-darker: #f7931e20;
  --secondary-color: #f15a24;
  --secondary-color-tint: #f15a2480;
  --secondary-color-shade: #f15a2460;
  --secondary-color-dark: #f15a2440;
  --secondary-color-darker: #f15a2420;
  --container-margin: 5rem;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box !important;
  scroll-behavior: smooth;
}

body {
  font-family: -apple-system, "Folty", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--bg-dark);
  color: var(--text-light);
  overflow-x: clip;
}

::-webkit-scrollbar {
  width: 0.3rem;
}

::-webkit-scrollbar-track {
  background: var(--bg-dark-tint);
}

::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 0.25rem;
}

a {
  text-decoration: none;
  outline: none;
}

ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Glosa W01 Text Black", serif;
}

input{
  font-family: "Folty", sans-serif;
}

/* Font sizes */
.fs-10 {
  font-size: calc(0.625rem + 0.25vw) !important;
}

.fs-12 {
  font-size: calc(0.75rem + 0.25vw) !important;
}

.fs-14 {
  font-size: calc(0.875rem + 0.25vw);
}

.fs-16 {
  font-size: calc(1rem + 0.25vw) !important;
}

.fs-18 {
  font-size: calc(1.125rem + 0.25vw) !important;
}

.fs-20 {
  font-size: calc(1.25rem + 0.25vw) !important;
}

.fs-22 {
  font-size: calc(1.375rem + 0.25vw);
}

.fs-24 {
  font-size: calc(1.5rem + 0.25vw);
}

.fs-26 {
  font-size: calc(1.625rem + 0.25vw);
}

.fs-28 {
  font-size: calc(1.75rem + 0.25vw);
}

.fs-30 {
  font-size: calc(1.875rem + 0.25vw);
}

.fs-32 {
  font-size: calc(2rem + 0.25vw);
}

.fs-34 {
  font-size: calc(2.125rem + 0.25vw);
}

.fs-36 {
  font-size: calc(2.25rem + 0.25vw);
}

.fs-38 {
  font-size: calc(2.375rem + 0.25vw);
}

.fs-40 {
  font-size: calc(2.5rem + 0.25vw);
}

.fs-42 {
  font-size: calc(2.625rem + 0.25vw);
}

.fs-44 {
  font-size: calc(2.75rem + 0.25vw);
}

.fs-46 {
  font-size: calc(2.875rem + 0.25vw);
}

.fs-48 {
  font-size: calc(3rem + 0.25vw);
}

.fs-50 {
  font-size: calc(3.125rem + 0.25vw);
}

.fs-52 {
  font-size: calc(3.25rem + 0.25vw);
}

.fs-54 {
  font-size: calc(3.375rem + 0.25vw);
}

.fs-56 {
  font-size: calc(3.5rem + 0.25vw);
}

.fs-58 {
  font-size: calc(3.625rem + 0.25vw);
}

.fs-60 {
  font-size: calc(3.75rem + 0.25vw) !important;
}

/* Font weights */
.fw-100 {
  font-weight: 100;
}

.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500;
}

/* Misc */
.btn {
  cursor: pointer;
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  font-family: "Folty", sans-serif;
  font-weight: 600;
  font-size: 1.3rem;
  outline: none;
  border: 1px solid var(--text-dark);
  transition: all 0.3s ease;
}

.btn:hover {
  transform: translateY(-0.2rem);
}

.btn:active {
  transform: translateY(0);
}

.btn.btn-primary {
  background: var(--primary-color);
  color: var(--text-dark);
}

.btn.btn-primary:hover {
  background: var(--primary-color-tint);
}

.btn.btn-primary-outline {
  border: 3px solid var(--primary-color);
  color: var(--primary-color);
  background: transparent;
  position: relative;
}

.btn.btn-primary-outline::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background: var(--primary-color);
  transition: all 0.3s ease;
  z-index: -1;
}

.btn.btn-primary-outline:hover::before {
  height: 100%;
}

.btn.btn-primary-outline:hover {
  color: var(--text-dark);
}

.spinner-border {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  border: 0.2rem solid var(--bg-dark-tint);
  border-top-color: var(--primary-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.spinner-border.spinner-border-sm {
  width: 2rem;
  height: 2rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
